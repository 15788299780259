* {
  margin: 0;
  padding: 0;
  font-family: arial;
}

.floatPanelOverMap{
  position: absolute;
  top: 75px;
  left: 10px;
  z-index: 1;
  width: 350px;
  border-radius: 8px !important;
  padding: 6px;
}

.noPadding{
  padding: 2px !important;
}

.littlePadding{
  padding: 8px !important;
}

.borderBlock{
  border-radius: 5px;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
}

.customUsuariosTable{
  height: 30px !important;
}

.MuiOutlinedInput-inputMarginDense{
  height: 16px !important;
}

.rightAlign{
  text-align: right;
}

.widthDialogConfirmacion{
  width: 600px;
}

.paddingButtonsPaginate {
  padding-top: 7px !important;
}

.littleTextPagination {
  font-size: 15px;
  text-align: right;
  padding-top: 9px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.sizeHeight {
  font-size: 10px;
}

.heighBlock{
  padding-top: 10px;
  height: 50px;
}

.MuiTableCell-head{
  padding: 2px !important;
}

.MuiTableCell-body{
  padding: 2px !important;
}

.topPaddingRow{
  padding-top: 15px !important;
}

.MuiButton-label{
  justify-content: left !important;
  font-size: 12px !important;  
}

.MuiButton-fullWidth{
  padding-left: 8px !important;
  padding-right: 5px !important;
  text-align: left;
  height: 36px !important;
}

.greyBackground{
  background-color: #fafafa;
}

.col20 {
  min-width: 20px !important;
}

.col50 {
  min-width: 50px !important;
}

.col100 {
  min-width: 100px !important;
}

.col150 {
  min-width: 150px !important;
}

.col200 {
  min-width: 200px !important;
}

.col250 {
  min-width: 250px !important;
}

.col300 {
  min-width: 300px !important;
}

.col400 {
  min-width: 400px !important;
}

.col500 {
  min-width: 500px !important;
}

.col600 {
  min-width: 600px !important;
}


.smallClass{
  height: 38px !important;
}

.smallClass div{
  height: 37px !important;
}

.smallClass div div{
  height: 37px !important;
}

.bigPanelMoney{
  height: 39px;
}

.smallClassSelect .MuiOutlinedInput-input{
  padding: 9px !important;
  text-align: left !important;
}

.eventoCell{
  background-color: #3f51b5;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
}

.redLabel{
  font-weight: bold;
  color: "#ee5555";
}

.alineacionIzquierda{
  text-align: left;
}

.bottomPadding{
  padding-bottom: 10px !important;
}

.smallTextDescription{
  font-size: 12px;
  padding: 5px;
  color: #888;
}

.littleTextButton{
  font-size: 10px !important;
  height: 37px;
}

.paddingMobileDevice{
  padding: 5px !important;
}

.heightControl{
  height: 30px !important;
}

.rightText {
  text-align: right;
  width: 100%;
  color: #888;
  font-size: 14px;
}

.imageLogo {
  width: 100% !important;
  height: auto;
}

.styleImage {
  width: 500px;
  height: auto;
}

.MuiDialog-paperFullScreen {
  overflow-x: hidden;
}

.ContainerGraph {
  position: relative;
}

.floatSelect {
  position: absolute !important;
  top: 5px;
  right: 0px;
  z-index: 10;
  font-size: 13px !important;
  width: 100px !important;
}

.selectWithoutMargin {  
  font-size: 13px !important;
  padding: 2px;
}

.floatSelect div {
  padding: 8px !important;
}

.floatButton {
  position: absolute !important;
  top: 5px;
  right: 105px;
  z-index: 10;
  font-size: 14px !important;
  padding: 4px 10px 2px 10px !important;
  height: 32px !important;
}

.productoEntregado {
  padding: 5px !important;
  border-radius: 5px !important;
  background-color: #39af0a !important;
  color: #fff !important;
  width: fit-content;
}

.entregaAnulada {
  padding: 5px !important;
  border-radius: 5px !important;
  background-color: #ff4652 !important;
  color: #fff !important;
}

.blockBase {
  border-radius: 5px !important;
  padding: 5px !important;
  width: fit-content;
}

.blockRed {
  background-color: #ff4652;
  color: #fff !important;
  margin: 5px;
  border-radius: 5px;
  color: #fff !important;
}

.blockRed h1,
.blockRed h3,
.blockRed div {
  color: #fff !important;
}

.blockBlue {
  background-color: #1451f7;
  color: #fff !important;
  margin: 5px;
  border-radius: 5px;
  color: #fff !important;
}

.blockBlue h1,
.blockBlue h3,
.blockBlue div {
  color: #fff !important;
}

.blockYellow {
  background-color: #fff780;
  margin: 5px;
  border-radius: 5px;
  color: #555 !important;
}

.blockYellow h1,
.blockYellow h3,
.blockYellow div {
  color: #555 !important;
}

.blockGreen {
  background-color: #39af0a;
  margin: 5px;
  border-radius: 5px;
  color: #fff !important;
}

.blockGreen h1,
.blockGreen h3,
.blockGreen div {
  color: #fff !important;
}

.hiddenClass {
  display: none !important;
}

.bloqueIndicador {
  background-color: #f2f2f2;
  margin: 2px;
  border-radius: 5px;
}

.leftText {
  text-align: right;
}

.greenBackground {
  background-color: #b5e9b5 !important;
}

.redBackground {
  background-color: #ffe4e4 !important;
}

.cursorEvento {
  cursor: pointer;
}

.contentindicador {
  display: block;
  position: relative;
  padding-top: 2px !important;
  text-align: right;
}

.titleIndicador {
  text-align: left;
  font-size: 12px;
  color: #fff;
  background-color: #666;
  border-radius: 4px;
  padding: 9px;
  margin: 1px;
}

.textDescription {
  padding: 2px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #888;
  font-size: 14px;
}

.boxContentInput {
  border-radius: 5px !important;
  border-color: rgb(199, 199, 199) !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.leftContentTitle {
  text-align: left !important;
  width: 100% !important;
  color: #888 !important;
}

.bolleanButtonCenter {
  text-align: center !important;
  display: inline-flex !important;
}

.smallPadding {
  padding: 5px !important;
}

.centerContent {
  text-align: center !important;
}

.subtituloTarjetas {
  font-size: 14px !important;
  color: #888 !important;
}

.marginTop {
  margin-top: 15px !important;
}

.fullWidth {
  width: 100% !important;
}

.fullWidthContent div button {
  width: 100% !important;
}

.boldText {
  color: #555 !important;
  font-weight: bold !important;
}

.hidenPanel {
  display: none !important;
}

.titleFile {
  padding-top: 7px;
  font-size: 14px;
  color: #555;
}

.panelUploadFiles {
  background-color: #ddd;
  border-radius: 5px;
}

.smallTextDashboard {
  font-size: 16px;
  text-align: center;
  color: #555;
}

.smallTextIndicador {
  font-size: 12px;
  text-align: right;
  color: rgb(0, 151, 124);
}

.smallText {
  font-size: 9px;
  text-align: center;
  color: #fff;
}

.bigText {
  font-size: 50px;
  text-align: center;
  color: #555;
}

h1 {
  color: #888;
}

h2 {
  color: #888;
}

h3 {
  color: #888;
}

h4 {
  color: #888;
}

h5 {
  color: #888;
}

.MuiDialogContent-root {
  height: 100% !important;
}

.borderTable {
  border-radius: 8px !important;
}

.tableModalNoPadding {
  padding: 8px !important;
}

.backgroundGrey {
  background: #eceff1;
}

.bottomButton {
  /* width: 324px; */
  /* position: absolute !important; */
  bottom: 0px !important;
}

.whiteButton {
  color: #fff !important;
  border-color: #fff !important;
}

.main-bg {
  background-color: #f5f5f5 !important;
}

.bgItemsSidebar {
  background-color: transparent;
  color: #fff;
  max-width: 100% !important;
}

.form-container {
  height: 100vh;
  background-image: url(/img/background-login.jpg);
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  padding-top: 10%;
  background-size: cover;
}

.form-containerRegistro {
  height: 100vh;
  background-image: url(/img/background-login.jpg);
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  padding-top: 0.9%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.form-select-document {
  background-image: url(/img/background-login.jpg);
  /* width: 100%; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  padding-top: 0.9%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.form {
  padding: 2em 3em;
  /* background-color: #fff; */
  margin: auto;
  width: 80%;
  /* height: 100vh; */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.formRegistro {
  padding: 1em 3em;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.loadingText{
  text-align: center;
  font-size: 22px;
  color: rgb(136, 136, 136);
  border: solid 1px #888;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background: #eee;
}
